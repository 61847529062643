import React from 'react';
import { SolucoesTopBanner } from 'components/Solucoes';
import { SOLUCOES_BANNER_HEIGHT } from 'components/Solucoes/SolucoesTopBanner/styles';
import { SmallText, ScrollableWrapper, Logos } from './styles';
import { px2rem } from 'src/util';

export default function FarmaciasTopBanner() {
  return (
    <SolucoesTopBanner
      image={require('assets/farmacias-topo.svg')}
      imageStyle={`
        @media screen and (min-width: 998px) {
          right: ${(37 / 1920) * 100}%;
          top: ${(109 / SOLUCOES_BANNER_HEIGHT) * 100}%;
          width: ${(793 / 1920) * 100}%;
        }
      
        @media screen and (max-width: 997px) {
          bottom: 295px;
          right: -79px;
          width: 425px;
        }
      `}
      customStyle={`
        

        @media screen and (min-width: 998px) {
          > div {
            padding-top: ${(276 / 1920) * 100}%;
          }
  
          h2 {
            margin-bottom: ${px2rem(32)};
            max-width: ${px2rem(560)};
          }
        }
        
        @media screen and (max-width: 997px) {
          h2 {
            margin-bottom: 407px;
            max-width: 280px;
          }
        }
      `}
      subtitle="Nexodata para Farmácias"
      title="Conexão com as maiores redes de hospitais e clínicas do país"
    >
      <SmallText>
        Aproveite destes serviços assim como as centenas de parceiros Nexodata
      </SmallText>
      <ScrollableWrapper>
        <Logos src={require('assets/logos-farmacias.jpg')} alt="" />
      </ScrollableWrapper>
    </SolucoesTopBanner>
  );
}
