import styled from 'styled-components';
import { px2rem } from 'src/util';

export const LogosWrapper = styled.div`
  @media screen and (min-width: 998px) {
    padding: ${(100 / 1920) * 100}% ${px2rem(20)};
  }

  @media screen and (max-width: 997px) {
    margin: 40px 0;
    overflow-x: auto;
    padding: 20px 0;
    width: 100%;
  }
`;

export const Logos = styled.img`
  margin: 0 auto;

  @media screen and (min-width: 998px) {
    max-width: 100%;
    width: ${px2rem(1255)};
  }

  @media screen and (max-width: 997px) {
    padding: 0 20px;
  }
`;
