import styled from 'styled-components';
import { LoadImage, SingleInputForm, Title } from 'components';
import { px2rem } from 'src/util';

export const Wrapper = styled.section`
  background-color: #e1e5e8;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 998px) {
    height: 0;
    padding-bottom: ${(1085 / 1920) * 100}%;
  }

  @media screen and (max-width: 997px) {
    padding: 71px 20px 780px;
  }
`;

export const BackgroundImage = styled(LoadImage)`
  position: absolute;

  @media screen and (min-width: 998px) {
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: translateX(-10%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    width: 100%;

    &.isVisible {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media screen and (max-width: 997px) {
    bottom: -10px;
    left: -197px;
    height: 825px;
  }
`;

export const Grid = styled.div`
  @media screen and (min-width: 998px) {
    align-items: center;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
  }

  @media screen and (max-width: 997px) {
    position: relative;
    z-index: 1;
  }
`;

export const Content = styled.div`
  ${Title} {
    margin-bottom: ${px2rem(32)};
  }

  @media screen and (min-width: 1441px) {
    grid-column: 9 / span 7;
  }

  @media screen and (max-width: 1440px) and (min-width: 998px) {
    grid-column: 9 / span 8;
  }

  @media screen and (max-width: 997px) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    ${Title} {
      font-size: 32px;
    }
  }
`;

export const StyledSingleInputForm = styled(SingleInputForm)`
  @media screen and (min-width: 998px) {
    height: ${px2rem(52)};
    width: ${px2rem(621)};
  }

  @media screen and (max-width: 997px) {
    align-self: center;
  }
`;
