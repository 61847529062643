import React from 'react';
import {
  SegurancaImage,
  SegurancaTooltip,
  AssinaturaImage,
  AssinaturaTooltip,
  HistoricoImage,
  HistoricoTooltip
} from './styles';
import { BlockWrapper, TextWrapper } from 'components/Solucoes/Tabs/styles';
import { Subtitle, Text, Title } from 'components';
import { Tabs } from 'components/Solucoes';

const data = [
  {
    title: 'Segurança',
    image: <SegurancaImage src={require('assets/seguranca-tab.png')} alt="" />,
    tooltip: (
      <SegurancaTooltip
        src={require('assets/seguranca-tooltip.svg')}
        alt="Segurança - Indicamos quando a prescrição eletrônica possui uma assinatura digital válida ou não, para que você se sinta seguro(a) para dispensar."
      />
    )
  },
  {
    title: 'Assinatura Digital',
    image: (
      <AssinaturaImage
        src={require('assets/assinatura-digital-tab.png')}
        alt=""
      />
    ),
    tooltip: (
      <AssinaturaTooltip
        src={require('assets/assinatura-digital-tooltip.svg')}
        alt="Autenticação - Para que seja realizada uma dispensa eletrônica, é necessário o cadastro como um farmacêutico ativo no CFF com usuário e senha."
      />
    )
  },
  {
    title: 'Histórico de Prescrições',
    image: <HistoricoImage src={require('assets/historico-tab.png')} alt="" />,
    tooltip: (
      <HistoricoTooltip
        src={require('assets/historico-tooltip.svg')}
        alt="Histórico - Com o CPF e a autorização do paciente, você consegue ver todas as prescrições eletrônicas que ele recebeu nos últimos tempos."
      />
    )
  }
];

export default function FarmaciasTabs() {
  return (
    <BlockWrapper>
      <TextWrapper>
        <Subtitle>Feito para você, farmacêutico</Subtitle>
        <Title>Pensado para facilitar a sua vida, e não o contrário</Title>
        <Text>
          O Nexodata para Farmácias tem tudo que o farmacêutico precisa para
          visualizar receitas e históricos de prescrição em detalhes e dispensar
          de forma segura, sem complicar ainda mais sua vida.
        </Text>
      </TextWrapper>
      <Tabs data={data} />
    </BlockWrapper>
  );
}
