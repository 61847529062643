import React from 'react';
import { Confie } from 'components/Solucoes';

const data = [
  {
    logo: require('assets/panvel-farmacias-logo.jpg'),
    text: `Essa integração Panvel e Nexodata é um grande avanço em agilidade e segurança para todos os envolvidos na cadeia de prescrição de medicamentos, com benefícios para médicos, pacientes e para a farmácia também. Mudança que veio para ficar!`,
    customerName: 'Roberto Coimbra',
    customerPosition: 'Diretor de Operações da Panvel'
  }
];

export default function FarmaciasConfie() {
  return <Confie data={data} />;
}
