import React from 'react';
import { LogosWrapper, Logos } from './styles';
import { Map } from 'components';

export default function FarmaciasMap() {
  return (
    <section>
      <Map />
      <LogosWrapper>
        <Logos src={require('assets/logos-presenca.jpg')} alt="" />
      </LogosWrapper>
    </section>
  );
}
