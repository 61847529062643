import React from 'react';
import { Contact, SEO } from 'components';
import {
  FarmaciasTopBanner,
  FarmaciasTabs,
  Futuro,
  FarmaciasMap,
  FarmaciasConfie,
  FarmaciasFAQ
} from 'components/Solucoes/Farmacias';

export default function Farmacias() {
  return (
    <>
      <SEO title="Nexodata" />
      <FarmaciasTopBanner />
      <FarmaciasTabs />
      <Futuro />
      <FarmaciasMap />
      <FarmaciasConfie />
      <FarmaciasFAQ />
      <Contact
        buttonText="Cadastrar minha farmácia"
        contactTitle={
          <>
            Ainda tem <br /> alguma dúvida?
          </>
        }
        type="farmacias"
      />
    </>
  );
}
