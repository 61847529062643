import styled from 'styled-components';
import { LoadImage } from 'components';
import { px2rem } from 'src/util';

export const SmallText = styled.p`
  color: #b3b7c4;
  font-size: ${px2rem(20)};
  letter-spacing: -0.045em;
  line-height: 115%;

  @media screen and (min-width: 998px) {
    margin-bottom: ${px2rem(35)};
  }

  @media screen and (max-width: 997px) {
    margin: 0 auto 30px;
    padding: 34px 20px 0;
  }
`;

export const ScrollableWrapper = styled.div`
  @media screen and (max-width: 997px) {
    display: flex;
    margin-bottom: 92px;
    overflow-x: auto;
    padding: 10px 0;
    width: 100%;
  }
`;

export const Logos = styled(LoadImage)`
  @media screen and (min-width: 998px) {
    width: ${px2rem(671)};
  }

  @media screen and (max-width: 997px) {
    margin: 0 auto;
    padding: 0 20px;
  }
`;
