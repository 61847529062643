import styled from 'styled-components';
import { StyledImage, Tooltip } from 'components/Solucoes/Tabs/styles';
import { px2rem } from 'src/util';

const StyledTooltip = styled(Tooltip)`
  width: ${px2rem(270)};
`;

export const SegurancaImage = styled(StyledImage)`
  @media screen and (min-width: 998px) {
    height: ${px2rem(327)};
    width: ${px2rem(695)};
  }
`;

export const SegurancaTooltip = styled(StyledTooltip)`
  top: ${px2rem(28)};
  left: ${px2rem(33)};
`;

export const AssinaturaImage = styled(StyledImage)`
  @media screen and (min-width: 998px) {
    height: ${px2rem(327)};
    width: ${px2rem(697)};
  }
`;

export const AssinaturaTooltip = styled(StyledTooltip)`
  top: ${px2rem(41)};
  right: ${px2rem(8)};
`;

export const HistoricoImage = styled(StyledImage)`
  @media screen and (min-width: 998px) {
    height: ${px2rem(327)};
    width: ${px2rem(609)};
  }
`;

export const HistoricoTooltip = styled(StyledTooltip)`
  top: ${px2rem(77)};
  right: -${px2rem(160)};
`;
