import React, { useMemo, useState, useRef } from 'react';
import styled from 'styled-components';
import { Popup } from 'components';
import { FAQ } from 'components/Solucoes';
import { px2rem } from 'src/util';
import Chevron from 'assets/chevron.svg';

const PopupLink = styled.button`
  align-items: center;
  display: flex;
  font-weight: 700;
  line-height: 150%;
  margin-top: ${px2rem(24)};
  text-align: left;
  text-decoration: underline;

  &::after {
    content: url(${Chevron});
    display: block;
    line-height: 0;
    margin-left: ${px2rem(15)};
  }

  @media screen and (min-width: 998px) {
    font-size: ${px2rem(20)};
  }

  @media screen and (max-width: 997px) {
    font-size: 16px;
  }
`;

const List = styled.ol`
  list-style: decimal;
  margin-bottom: ${px2rem(20)};
  padding-left: ${px2rem(20)};
`;

const PortalLink = styled.a`
  color: #00778f;
  text-decoration: underline;
`;

export default function FarmaciasFAQ() {
  const linkRef = useRef<HTMLButtonElement>(null);
  const [popupActive, setPopupActive] = useState(false);

  function togglePopup() {
    setPopupActive(state => !state);
  }

  const data = useMemo(
    () => [
      {
        question: `Como integrar a(s) minha(s) farmácia(s) a Nexodata?`,
        answer: (
          <>
            <p>Você tem 2 opções de integração:</p>
            <List>
              <li>
                Através do nosso{' '}
                <PortalLink href="https://farmacias.nexodata.com.br/login">
                  portal da Nexodata
                </PortalLink>{' '}
                de dispensação de medicamentos.
              </li>
              <li>
                Através da Nexodata Atrium API, que pode ser integrada
                diretamente no sistema da sua rede de farmácias.
              </li>
            </List>
            <p>
              Para mais informações entre em contato conosco através dos nosso
              canais.
            </p>
          </>
        )
      },
      {
        question: `Como acontece a dispensa digital pela Nexodata?`,
        answer: (
          <p>
            O paciente recebe a receita digital via SMS ou e-mail. A receita
            apresentará um código, que sempre começa com NX. Para dispensar o
            medicamento na plataforma da Nexodata é preciso escanear o Qrcode ou
            código de barras, ou digitar o código recebido. Em seguida, é só
            incluir as quantidades de medicamentos que constam na receita,
            clicar em “finalizar” e pronto, a receita ficará com o status de
            “dispensado”.
          </p>
        )
      },
      {
        question: `Quais medicamentos podem ser dispensados com uma receita digital pela Nexodata?`,
        answer: (
          <>
            <p>
              Depende do tipo de medicamento e receita, de acordo com a tabela
              de referência.
            </p>
            <PopupLink onClick={togglePopup} ref={linkRef}>
              Clique aqui e confira a tabela de referência
            </PopupLink>
          </>
        )
      }
    ],
    [linkRef]
  );

  return (
    <>
      <FAQ data={data} />
      {popupActive && <Popup onClose={togglePopup} linkRef={linkRef} />}
    </>
  );
}
